import React from 'react';
import { Flex, Box, Image } from '@chakra-ui/core';

import Layout from '../components/layout';
import ContentContainer from '../components/ContentContainer';
import { APP_CONTENT_WIDE_WIDTH, APP_CONTENT_DESKTOP_WIDTH } from '../styles/sizes';
import { H1, H2, H3, H4, Text } from '@typography';
import { DesktopOnly, MobileOnly } from '../layouts/Footer';
import { cloudfrontBaseUrl } from '../lib/consts';
import { colors } from '@theme';
import { Tag } from '@zeta';
import { Metadata, PriceChart } from '@zetaplus';
import TopSection from '../components/zetaPlus/TopSection';

const zetaPlusOfferings = [
  {
    title: 'Automation+',
    price: undefined,
    color: undefined,
    desktopImage: `${cloudfrontBaseUrl}/zeta-plus/AutomationDesktop.png`,
    mobileImage: `${cloudfrontBaseUrl}/zeta-plus/AutomationMobile.png`,
    body:
      'Put banking on autopilot. Use custom rules to set aside funds for your bills or goals. We’ll even automate sending money to your external accounts. The only thing you need to worry about is getting paid.',
    alt: 'Automation+ sample',
  },
  {
    title: 'Cards+',
    price: undefined,
    color: undefined,
    desktopImage: `${cloudfrontBaseUrl}/zeta-plus/CardsDesktop.png`,
    mobileImage: `${cloudfrontBaseUrl}/zeta-plus/CardsMobile.png`,
    body:
      'Unlock additional virtual cards to help you better manage your spending, privacy and security. Each card comes with custom card controls that let you set spending limits or merchant controls. If a transaction is over the limit, or doesn’t meet the requirements you set, we’ll automatically decline it.',
    alt: 'Cards+ sample',
  },
  {
    title: 'Members+',
    price: undefined,
    color: undefined,
    desktopImage: `${cloudfrontBaseUrl}/zeta-plus/MembersDesktop.png`,
    mobileImage: `${cloudfrontBaseUrl}/zeta-plus/MembersMobile.png`,
    body:
      "With Members+, you can seamlessly add up to five members to a single family account, making it easy to spend, track, and collaborate on your family finances. Whether it's for families, poly relationships, roommates, or dependents, Members+ offers the flexibility to manage funds together—while maintaining the security and privacy you expect from your bank.",
    alt: 'Members+ sample',
  },
  {
    title: 'Savings+',
    price: undefined,
    color: undefined,
    desktopImage: `${cloudfrontBaseUrl}/zeta-plus/SavingsDesktop.png`,
    mobileImage: `${cloudfrontBaseUrl}/zeta-plus/SavingsMobile.png`,
    body:
      'A savings account designed to help you grow your financial security with ease. With competitive interest rate and features like automated savings, and personalized financial advice, Savings+ empowers families to achieve their financial goals.',
    alt: 'Savings+ sample',
    tag: 'Coming soon',
    tagWidth: { mobile: '100px', desktop: '150px' },
  },
];

const ZetaPlus = () => {
  return (
    <Layout noRootContainer transparent mode="home" newNav>
      <Metadata />
      <TopSection />
      <Flex direction="column" className="premium-offers">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <Flex
            className="offers-heading"
            pt={['40px', '56px']}
            align="center"
            direction="column"
            px={['16px', '0px']}
          >
            <H2>Supercharge your account</H2>

            <Box pt={['8px', '22px']} width={['294px', '544px']}>
              <H4 center as="p">
                While core Zeta accounts remain free, Zeta+ levels up your account to do
                more for you:
              </H4>
            </Box>
          </Flex>
          <PriceChart />
        </ContentContainer>
      </Flex>
      <Flex direction="column" className="premium-offers">
        <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
          <Flex
            className="offers-heading"
            pt={['40px', '56px']}
            align="center"
            direction="column"
          >
            <DesktopOnly>
              <H2>Meet our premium features</H2>
            </DesktopOnly>

            <MobileOnly>
              <H2>
                Meet our
                <br />
                premium features
              </H2>
            </MobileOnly>

            <Box pt={['8px', '22px']} width={['294px', '544px']}>
              <H4 center as="p">
                Here are the deets on some of
                <br />
                our premium features:
              </H4>
            </Box>
          </Flex>

          <Box
            pt={['54px', '40px']}
            px={['18px', '0px']}
            pb={['60px', '120px']}
            className="offers-list"
          >
            {zetaPlusOfferings.map((offering, idx) => (
              <Flex
                key={idx}
                className={`${offering.title}`}
                bg={offering.color}
                direction={['column', 'row']}
                justifyContent={['space-between']}
                height={['auto', '370px']}
                width="100%"
                overflow="hidden"
                mt={idx !== 0 ? '40px' : 0}
                sx={{
                  border: `1px solid #000000`,
                  borderRadius: ['15px', '32px'],
                }}
                pb={['24px', 0]}
              >
                <Flex
                  className={`${offering.title}-body`}
                  direction="column"
                  justifyContent="center"
                  pb={[0, '24px']}
                >
                  <Flex justify="center">
                    <MobileOnly>
                      <Image src={offering.mobileImage} alt={offering.alt} />
                    </MobileOnly>
                  </Flex>

                  <Box pl={'27px'} pt="16px">
                    <Flex direction="row" align="center">
                      <H3 style={{ color: colors.primary }}>{offering.title}</H3>
                      {offering.tag?.length > 0 && (
                        <div style={{ marginLeft: '1rem' }}>
                          <Tag
                            color="#333333"
                            borderColor="white"
                            textColor="white"
                            height={['28px', '26px']}
                            text={offering.tag}
                            fontSize="12px"
                          />
                        </div>
                      )}
                    </Flex>
                  </Box>
                  <Box px="27px" pt="24px" width={[undefined, '400px']}>
                    <Text>{offering.body}</Text>
                  </Box>
                </Flex>

                <DesktopOnly>
                  <Flex
                    style={{
                      height: '100%',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Image
                      mr={idx === 1 && '-10px'}
                      src={offering.desktopImage}
                      alt={offering.alt}
                      style={{ height: '100%', marginLeft: 'auto' }}
                    />
                  </Flex>
                </DesktopOnly>
              </Flex>
            ))}
          </Box>
        </ContentContainer>
      </Flex>
    </Layout>
  );
};

const dynamicSizes = {
  mobileTagMarginTop: idx => {
    if (idx === 1) {
      return '-100px';
    }

    if (idx === 2) {
      return '-50px';
    }

    if (idx === 3) {
      return '-10px';
    }
  },
  mobileBodyWidth: idx => {
    if (idx === 0) {
      return '315px';
    }

    if (idx === 1) {
      return '290px';
    }

    if (idx === 2 || idx === 3) {
      return '300px';
    }
  },
};

export default ZetaPlus;
