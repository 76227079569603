import React from 'react';
import { Flex } from '@chakra-ui/core';
import Helmet from 'react-helmet';

const Metadata = () => {
  const helmet = React.useMemo(
    () => (
      <Helmet>
        <title>Zeta Plus: Meet our premium features | Zeta</title>
        <link rel="canonical" href="https://www.askzeta.com/zeta-plus" />
        <meta
          name="description"
          content="Upgrade to Zeta Plus to super-charge your personal or joint account with features designed to optimize your financial goals."
        />
        <meta
          property="og:description"
          content="Upgrade to Zeta Plus to super-charge your personal or joint account with features designed to optimize your financial goals."
        />
        <meta
          name="keywords"
          content="joint bank account, personal bank account, banking, personal account, best joint bank account, family tax services, family savings account, zeta plus, zeta features, zeta account benefits"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Zeta | Meet our premium features" />
        <meta property="og:site_name" content="Zeta | Meet our premium features" />
        <meta property="og:url" content="https://www.askzeta.com/zeta-plus" />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta property="fb:app_id" content="2316257168617802" />
      </Helmet>
    ),
    [],
  );
  return <Flex>{helmet}</Flex>;
};

export default Metadata;
